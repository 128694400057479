import { Form } from '@unform/web';
import {
  addMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  parseISO,
  subDays,
  subHours,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { HiDownload, HiOutlineInformationCircle } from 'react-icons/hi';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { BsFillChatDotsFill } from 'react-icons/bs';
import { useAuth } from '~/hooks/Auth';
import { useValidError } from '~/hooks/ValidError';
import api from '~/services/api';
import { randomColor } from '~/utils/colorGenerator';
import { formatPrice } from '~/utils/format';
import getValidationErros from '~/utils/getValidationsErrors';

import CircleName from '~/components/CircleName';
import CompositionChart from '~/components/CompositionChart';
import Dropzone from '~/components/Dropzone';
import Header from '~/components/HeaderDashboard';
import Input from '~/components/Input';
import InputNote from '~/components/InputNote';
import InputRadio from '~/components/InputRadio';
import Loading from '~/components/Loading';
import MiniProfile from '~/components/MiniProfile';
import PriceChart from '~/components/PriceChart';
import Status from '~/components/Status';
import WarningBox from '~/components/WarningBox';
import {
  Card,
  ChatOptions,
  Container,
  Modal,
  Pendency,
  PersonalInfos,
  Simulation,
} from './styles';

export default function Dashboard() {
  const { user } = useAuth();
  const { validError } = useValidError();
  const history = useHistory();
  const params = useParams();
  const formRefReport = useRef(null);
  const formRefRegistrationForms = useRef(null);
  const formRefReleaseSignatureOnline = useRef(null);
  const formRefReleaseSignatureCaixa = useRef(null);
  const [order, setOrder] = useState({});
  const [participants, setParticipants] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [showShowBankSlip, setShowShowBankSlip] = useState(false);
  const [showAttachEngineeringReport, setShowAttachEngineeringReport] =
    useState(false);
  const [showSubmitRegistrationForms, setShowSubmitRegistrationForms] =
    useState(false);
  const [showReleaseSignatureOnline, setShowReleaseSignatureOnline] =
    useState(false);
  const [showReleaseSignatureCaixa, setShowReleaseSignatureCaixa] =
    useState(false);
  const [bankSlips, setBankSlips] = useState([]);
  const [reports, setReports] = useState([]);
  const [registrationForms, setRegistrationForms] = useState([]);
  const [signatureDocuments, setSignatureDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadOS = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(`correspondents/oss/${params.slug}`);
      if (!user.isAdmin) {
        if (!user.isCorrespondente.avancado) {
          if (!response.data.correspondente_id) {
            history.push(`${process.env.PUBLIC_URL}/home`);
          } else if (response.data.correspondente_id !== user.id) {
            history.push(`${process.env.PUBLIC_URL}/home`);
          }
        }
      }

      let currentDate = new Date();
      const days = differenceInDays(
        currentDate,
        parseISO(response.data.created_at),
      );
      currentDate = subDays(currentDate, days);
      const hours = differenceInHours(
        currentDate,
        parseISO(response.data.created_at),
      );
      currentDate = subHours(currentDate, hours);
      const minutes = differenceInMinutes(
        currentDate,
        parseISO(response.data.created_at),
      );
      const opening = `${days} ${days === 1 ? 'dia' : 'dias'}, ${hours} ${
        hours === 1 ? 'horas' : 'horas'
      } e ${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`;

      let budget = 0;

      if (response.data.orc_ambientes.length > 0) {
        budget = response.data.orc_ambientes.reduce((acumulador, current) => {
          return acumulador + current.vlrFinal;
        }, 0);
      }

      let fgts_value = 0;
      let entry_value = 0;
      let amount_financed = 0;
      if (response.data.simulacao) {
        fgts_value = response.data.simulacao.vlrFgts;
        entry_value = response.data.simulacao.vlrEntrada;
        amount_financed = response.data.simulacao.vlrFinanciado;
      }

      let initial_value_installment_date = '-';
      let final_value_installment_date = '-';

      if (response.data.simulacao && response.data.simulacao.dt_analise) {
        initial_value_installment_date = format(
          parseISO(response.data.simulacao.dt_analise),
          'PPP',
          {
            locale: ptBR,
          },
        );

        final_value_installment_date = format(
          addMonths(
            parseISO(response.data.simulacao.dt_analise),
            response.data.simulacao.prazo,
          ),
          'PPP',
          {
            locale: ptBR,
          },
        );
      }

      const data = {
        id: response.data.id,
        opening,
        step: response.data.status.step.ordem,
        statusId: response.data.status.id,
        status: response.data.status ? response.data.status.status : '-',
        origin: response.data.origem ? response.data.origem.origem : '-',
        type: response.data.finalidade
          ? response.data.finalidade.finalidade
          : '-',
        typeID: response.data.finalidade ? response.data.finalidade.id : -1,
        document: response.data.cliente ? response.data.cliente.documento : '-',
        name: response.data.cliente ? response.data.cliente.nome : '-',
        email: response.data.cliente ? response.data.cliente.email : '-',
        cellphone: response.data.cliente ? response.data.cliente.celular : '-',
        married: response.data.cliente ? response.data.cliente.casado : '-',
        state: response.data.terreno ? response.data.terreno.estado.sigla : '-',
        county: response.data.terreno ? response.data.terreno.municipio : '-',
        agency: response.data.agencia
          ? response.data.agencia.toString().padStart(4, '0')
          : '-',
        pis_number: response.data.numPis ? response.data.numPis : '-',
        spouse: response.data.conjuge ? response.data.conjuge.nome : '-',
        spouse_email: response.data.conjuge ? response.data.conjuge.email : '-',
        spouse_phone: response.data.conjuge
          ? response.data.conjuge.celular
          : '-',
        spouse_document: response.data.conjuge
          ? response.data.conjuge.cpf
          : '-',
        builder: response.data.construtor ? response.data.construtor.name : '-',
        builder_email: response.data.construtor
          ? response.data.construtor.email
          : '-',
        builder_telephone:
          response.data.construtor && response.data.construtor.construtech
            ? response.data.construtor.construtech.telefone
            : '-',
        construtech:
          response.data.construtor && response.data.construtor.construtech
            ? response.data.construtor.construtech.nome_fantasia
            : '-',
        budget: formatPrice(budget),
        modality: response.data.simulacao
          ? response.data.simulacao.vlrFinanciado
          : '-',
        property_value: response.data.simulacao
          ? response.data.simulacao.vlrImovPronto
          : '-',
        entry_value: formatPrice(entry_value),
        fgts_value: formatPrice(fgts_value),
        initial_value_installment: response.data.simulacao
          ? formatPrice(response.data.simulacao.vlrParcInic)
          : '-',
        initial_value_installment_date,
        final_value_installment: response.data.simulacao
          ? formatPrice(response.data.simulacao.vlrParcFinal)
          : '-',
        final_value_installment_date,
        amortization:
          response.data.simulacao && response.data.simulacao.amortizacao
            ? response.data.simulacao.amortizacao.amortizacao
            : '-',
        amount_financed: formatPrice(amount_financed),
        subsidy_amount: response.data.simulacao
          ? formatPrice(response.data.simulacao.vlrSubsidio)
          : '-',
        actual_entry_value: response.data.simulacao
          ? formatPrice(response.data.simulacao.vlrEntrada)
          : '-',
        land_value: response.data.simulacao
          ? formatPrice(response.data.simulacao.vlrTerreno)
          : '-',
        deadline: response.data.simulacao ? response.data.simulacao.prazo : '-',
        tax: response.data.simulacao ? response.data.simulacao.taxa : '-',
        bank:
          response.data.simulacao &&
          response.data.simulacao.amortizacao &&
          response.data.simulacao.amortizacao.banco
            ? response.data.simulacao.amortizacao.banco.banco
            : '',
        proponents: response.data.participantes.length > 0 ? 'Sim' : 'Não',
        pendency:
          response.data.status.responsavel_id === 1
            ? 'builder'
            : 'correspondent',
        complement_form: response.data.complement_data || null,
      };

      const participantsData = [];
      const clientNameParts = data.name.split(' ');
      const client = {
        color: randomColor(),
        name: `${clientNameParts[0][0]}${
          clientNameParts[clientNameParts.length - 1][0]
        }`.toUpperCase(),
      };
      participantsData.push(client);

      if (response.data.conjuge && response.data.conjuge.nome) {
        const spouseNameParts = response.data.conjuge.nome.split(' ');
        const spouse = {
          color: randomColor(),
          name: `${spouseNameParts[0][0]}${
            spouseNameParts[spouseNameParts.length - 1][0]
          }`.toUpperCase(),
        };
        participantsData.push(spouse);
      }

      if (response.data.participantes.length > 0) {
        response.data.participantes.forEach(participant => {
          const participantNameParts = participant.nome.split(' ');
          const participantData = {
            color: randomColor(),
            name: `${participantNameParts[0][0]}${
              participantNameParts[participantNameParts.length - 1][0]
            }`.toUpperCase(),
          };
          participantsData.push(participantData);
        });
      }

      setParticipants(participantsData);

      const chartData = [
        {
          asset: 'Valor do FGTS',
          percent: fgts_value,
        },
        {
          asset: 'Valor de entrada',
          percent: entry_value,
        },
        {
          asset: 'Valor financiado',
          percent: amount_financed,
        },
      ];

      setDataChart(chartData);
      setOrder(data);
    } catch (error) {
      validError(error);
    } finally {
      setLoading(false);
    }
  }, [
    history,
    params.slug,
    user.id,
    user.isAdmin,
    user.isCorrespondente,
    validError,
  ]);

  useEffect(() => {
    loadOS();
  }, [loadOS]);

  const RadioOptionsSalesman = useMemo(
    () => [
      {
        label: 'Vistoria aprovada',
        id: 'approvedSalesman',
        value: 'approved',
      },
      {
        label: 'Vistoria  reprovada',
        id: 'disapprovedSalesman',
        value: 'disapproved',
      },
    ],
    [],
  );

  const handleShowBankSlip = useCallback(() => {
    setShowShowBankSlip(true);
  }, []);

  const handleShowAttachEngineeringReport = useCallback(() => {
    setShowAttachEngineeringReport(true);
  }, []);

  const handleShowSubmitRegistrationForms = useCallback(() => {
    setShowSubmitRegistrationForms(true);
  }, []);

  const handleShowReleaseSignature = useCallback(() => {
    if (order.bank !== 'Caixa Econômica Federal') {
      setShowReleaseSignatureOnline(true);
    } else {
      setShowReleaseSignatureCaixa(true);
    }
  }, [order.bank]);

  const handleClose = useCallback(() => {
    setShowShowBankSlip(false);
    setShowAttachEngineeringReport(false);
    setShowSubmitRegistrationForms(false);
    setShowReleaseSignatureOnline(false);
    setShowReleaseSignatureCaixa(false);
  }, []);

  const handleDropBankSlips = useCallback(files => {
    setBankSlips(state => [...state, ...files]);
  }, []);

  const handleRemoveBankSlip = useCallback(
    index => {
      const newBankSlips = bankSlips.slice();
      newBankSlips.splice(index, 1);
      setBankSlips(newBankSlips);
    },
    [bankSlips],
  );

  const handleSubmitBankSlips = useCallback(async () => {
    try {
      setLoading(true);
      const bankSlipsPromise = new Promise(resolve => {
        bankSlips.forEach(async (bankSlip, index) => {
          const fileData = new FormData();
          fileData.append('file', bankSlip);
          fileData.append('os_id', params.slug);
          fileData.append('tpArquivo_id', 14);
          fileData.append('documento_extra', 1);
          fileData.append('precisa_validacao', 0);
          fileData.append('user_create_id', user.id);
          fileData.append('user_update_id', user.id);

          await api.post('correspondents/files', fileData);

          if (bankSlips.length === index + 1) {
            resolve();
          }
        });
      });
      await bankSlipsPromise;

      const formData = {
        status_id: 13,
      };

      await api.put(`correspondents/oss/${params.slug}`, formData);

      Swal.fire(
        'Tudo Certo!',
        'Documentos salvos com sucesso.',
        'success',
      ).then(() => {
        setBankSlips([]);
        handleClose();
        loadOS();
      });
    } catch (error) {
      validError(error);
    } finally {
      setLoading(false);
    }
  }, [bankSlips, handleClose, loadOS, params.slug, user.id, validError]);

  const handleDropReports = useCallback(files => {
    setReports(state => [...state, ...files]);
  }, []);

  const handleRemoveReport = useCallback(
    index => {
      const newReports = reports.slice();
      newReports.splice(index, 1);
      setReports(newReports);
    },
    [reports],
  );

  const handleSubmitReports = useCallback(
    async data => {
      setLoading(true);
      try {
        formRefReport.current?.setErrors({});
        const schema = Yup.object().shape({
          survey: Yup.string().required('Selecione uma das opções acima'),
          note: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (reports.length > 0) {
          const reportsPromise = new Promise(resolve => {
            reports.forEach(async (report, index) => {
              const fileData = new FormData();
              fileData.append('file', report);
              fileData.append('os_id', params.slug);
              fileData.append('tpArquivo_id', 15);
              fileData.append('obsCorresp', data.note);
              fileData.append(
                'resultado_id',
                data.survey === 'approved' ? 1 : 2,
              );
              fileData.append('documento_extra', 0);
              fileData.append('precisa_validacao', 0);
              fileData.append('user_create_id', user.id);
              fileData.append('user_update_id', user.id);

              await api.post('correspondents/files', fileData);

              if (reports.length === index + 1) {
                resolve();
              }
            });
          });

          await reportsPromise;
        } else {
          const formData = {
            os_id: params.slug,
            tpArquivo_id: 15,
            obsCorresp: data.note,
            resultado_id: data.survey === 'approved' ? 1 : 2,
            documento_extra: 0,
            precisa_validacao: 0,
            user_create_id: user.id,
            user_update_id: user.id,
          };

          await api.post('correspondents/files', formData);
        }

        let formData = {
          status_id: 15,
        };

        if (data.survey === 'approved') {
          formData = {
            status_id: 17,
          };
        }

        await api.put(`correspondents/oss/${params.slug}`, formData);

        Swal.fire(
          'Tudo Certo!',
          'Documentos salvos com sucesso.',
          'success',
        ).then(() => {
          setReports([]);
          handleClose();
          loadOS();
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRefReport.current?.setErrors(errors);
        } else {
          validError(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [reports, params.slug, user.id, handleClose, loadOS, validError],
  );

  const handleDropRegistrationForms = useCallback(files => {
    setRegistrationForms(state => [...state, ...files]);
  }, []);

  const handleRemoveRegistrationForm = useCallback(
    index => {
      const newRegistrationForms = registrationForms.slice();
      newRegistrationForms.splice(index, 1);
      setRegistrationForms(newRegistrationForms);
    },
    [registrationForms],
  );

  const handleSubmitRegistrationForms = useCallback(
    async data => {
      setLoading(true);
      try {
        formRefRegistrationForms.current?.setErrors({});
        const schema = Yup.object().shape({
          note: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (registrationForms.length > 0) {
          const registrationFormsPromise = new Promise(resolve => {
            registrationForms.forEach(async (registrationForm, index) => {
              const fileData = new FormData();
              fileData.append('file', registrationForm);
              fileData.append('os_id', params.slug);
              fileData.append('tpArquivo_id', 16);
              fileData.append('obsCorresp', data.note);
              fileData.append('documento_extra', 0);
              fileData.append('precisa_validacao', 0);
              fileData.append('user_create_id', user.id);
              fileData.append('user_update_id', user.id);

              await api.post('correspondents/files', fileData);

              if (registrationForms.length === index + 1) {
                resolve();
              }
            });
          });
          await registrationFormsPromise;

          const formData = {
            status_id: 18,
          };

          await api.put(`correspondents/oss/${params.slug}`, formData);

          Swal.fire(
            'Tudo Certo!',
            'Documentos salvos com sucesso.',
            'success',
          ).then(() => {
            setRegistrationForms([]);
            handleClose();
            loadOS();
          });
        } else {
          Swal.fire('Alerta!', 'Nenhum arquivo foi enviado.', 'warning');
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRefRegistrationForms.current?.setErrors(errors);
        } else {
          validError(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [registrationForms, params.slug, user.id, handleClose, loadOS, validError],
  );

  const handleDropReleaseSignatureOnline = useCallback(files => {
    setSignatureDocuments(state => [...state, ...files]);
  }, []);

  const handleRemoveReleaseSignatureOnline = useCallback(
    index => {
      const newRegistrationForms = registrationForms.slice();
      newRegistrationForms.splice(index, 1);
      setSignatureDocuments(newRegistrationForms);
    },
    [registrationForms],
  );

  const handleSubmitReleaseSignatureOnline = useCallback(
    async data => {
      setLoading(true);
      try {
        formRefReleaseSignatureOnline.current?.setErrors({});
        const schema = Yup.object().shape({
          link: Yup.string().required('O link é obrigatório'),
          note: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (signatureDocuments.length > 0) {
          const signatureDocumentsPromise = new Promise(resolve => {
            signatureDocuments.forEach(async (signatureDocument, index) => {
              const fileData = new FormData();
              fileData.append('file', signatureDocument);
              fileData.append('os_id', params.slug);
              fileData.append('tpArquivo_id', 17);
              fileData.append('obsCorresp', data.note);
              fileData.append('documento_extra', 0);
              fileData.append('precisa_validacao', 0);
              fileData.append('user_create_id', user.id);
              fileData.append('user_update_id', user.id);

              await api.post('correspondents/files', fileData);

              if (signatureDocuments.length === index + 1) {
                resolve();
              }
            });
          });
          await signatureDocumentsPromise;
        } else {
          const formData = {
            os_id: params.slug,
            tpArquivo_id: 17,
            obsCorresp: data.note,
            documento_extra: 0,
            precisa_validacao: 0,
            user_create_id: user.id,
            user_update_id: user.id,
          };

          await api.post('correspondents/files', formData);
        }

        const formData = {
          status_id: 22,
        };

        await api.put(`correspondents/oss/${params.slug}`, formData);

        Swal.fire(
          'Tudo Certo!',
          'Documentos salvos com sucesso.',
          'success',
        ).then(() => {
          setSignatureDocuments([]);
          handleClose();
          loadOS();
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRefReleaseSignatureOnline.current?.setErrors(errors);
        } else {
          validError(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [signatureDocuments, params.slug, user.id, handleClose, loadOS, validError],
  );

  const handleSubmitReleaseSignatureCaixa = useCallback(
    async data => {
      setLoading(true);
      try {
        formRefReleaseSignatureCaixa.current?.setErrors({});
        const schema = Yup.object().shape({
          note: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.note) {
          const formData = {
            os_id: params.slug,
            tpArquivo_id: 17,
            obsCorresp: data.note,
            documento_extra: 0,
            precisa_validacao: 0,
            user_create_id: user.id,
            user_update_id: user.id,
          };

          await api.post('correspondents/files', formData);
        }

        const formData = {
          status_id: 22,
        };

        await api.put(`correspondents/oss/${params.slug}`, formData);

        Swal.fire(
          'Tudo Certo!',
          'Documentos salvos com sucesso.',
          'success',
        ).then(() => {
          handleClose();
          loadOS();
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRefReleaseSignatureCaixa.current?.setErrors(errors);
        } else {
          validError(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [handleClose, loadOS, params.slug, user.id, validError],
  );

  const handleClickDownloadProposal = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `correspondents/oss/${params.slug}/proposal`,
        {
          responseType: 'blob',
        },
      );
      const urlData = window.URL.createObjectURL(new Blob([response.data]), {
        type: 'application/pdf',
      });

      const link = document.createElement('a');
      link.href = urlData;
      link.download = `Proposta.pdf`;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(urlData);
        link.remove();
      }, 100);
    } catch (error) {
      validError(error);
    } finally {
      setLoading(false);
    }
  }, [params.slug, validError]);

  const handleClickDownloadRegistrationForm = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `correspondents/oss/${params.slug}/registration-form`,
        {
          responseType: 'blob',
        },
      );
      const urlData = window.URL.createObjectURL(new Blob([response.data]), {
        type: 'application/pdf',
      });

      const link = document.createElement('a');
      link.href = urlData;
      link.download = `Ficha_Cadastral.pdf`;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(urlData);
        link.remove();
      }, 100);
    } catch (error) {
      validError(error);
    } finally {
      setLoading(false);
    }
  }, [params.slug, validError]);

  const handleClickBtnChat = useCallback(async () => {
    const response = await api.post(`correspondents/chats`, {
      os_id: parseInt(params.slug, 10),
      type: 'correspondent',
    });

    history.push(`${process.env.PUBLIC_URL}/mensagens/${response.data.id}`);
  }, [history, params.slug]);

  return (
    <div className="lg:flex">
      <Header
        slug={params.slug}
        onShowBankSlip={handleShowBankSlip}
        onShowAttachEngineeringReport={handleShowAttachEngineeringReport}
        onShowSubmitRegistrationForms={handleShowSubmitRegistrationForms}
        onShowReleaseSignature={handleShowReleaseSignature}
        onClickDownloadProposal={handleClickDownloadProposal}
      />
      {Object.keys(order).length > 0 && (
        <Container className="pb-5">
          <div className="container mx-auto">
            <div className="flex flex-wrap items-center justify-between pt-4">
              <div className="w-full md:w-9/12 lg:w-6/12">
                <div className="flex items-center px-5">
                  <button
                    type="button"
                    onClick={() =>
                      history.push(`${process.env.PUBLIC_URL}/home`)
                    }
                    className="border-0 bg-transparent"
                  >
                    <IoIosArrowBack size={25} color="#CCCCCC" />
                  </button>
                  <div>
                    <h1 className="text-2xl color-onyx font-semibold mx-8">
                      Ordem de Serviço
                    </h1>
                    <Pendency
                      type={order.pendency}
                      className="font-medium mx-8"
                    >
                      Pendente{' '}
                      {order.pendency === 'builder'
                        ? 'construtor'
                        : 'correspondente'}
                    </Pendency>
                  </div>
                </div>
              </div>
              <div className="hidden md:block pl-6 pt-4 md:w-3/12">
                <div className="flex justify-end items-center">
                  <MiniProfile />
                </div>
              </div>
            </div>
            <div className="flex md:flex-col lg:flex-row flex-wrap lg:justify-between items-end px-4 md:px-8 mt-10 md:mt-0 mb-10">
              <div className="w-full md:w-9/12 lg:w-7/12 self-start lg:px-5">
                <p className="text-lg">
                  Estamos aqui para tirar o seu sonho do papel e construir sua
                  casa juntos. Aqui está sua solicitação de financiamento
                </p>
              </div>
              <div className="w-full md:w-5/12 lg:w-3/12 mt-3 md:mt-0 lg:px-5">
                <div className="flex md:justify-end">
                  <BiTimeFive size={20} color="#A1C64D" />
                  <small className="font-medium">
                    Abertura OS: {order.opening}
                  </small>
                </div>
              </div>
            </div>
            <div className="flex flex-row flex-wrap px-4 md:px-8 mb-5 md:mb-10">
              <Status step={order.step} />
            </div>
            <div className="flex flex-row flex-wrap px-4 md:px-8">
              <div className="w-full flex justify-end mb-5 md:mb-10">
                {participants.map((participant, index) => (
                  <CircleName
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    color={participant.color}
                    name={participant.name}
                  />
                ))}
              </div>
              <div className="w-full lg:px-5">
                <WarningBox
                  slug={params.slug}
                  statusId={order.statusId}
                  onShowBankSlip={handleShowBankSlip}
                  onShowAttachEngineeringReport={
                    handleShowAttachEngineeringReport
                  }
                  onShowSubmitRegistrationForms={
                    handleShowSubmitRegistrationForms
                  }
                  onShowReleaseSignature={handleShowReleaseSignature}
                />
              </div>
              <div className="w-full lg:px-5 mb-10">
                <button
                  type="button"
                  className="w-full flex justify-center items-center download-record py-2"
                  onClick={handleClickDownloadRegistrationForm}
                >
                  Baixar Ficha Cadastral
                  <HiDownload size={15} color="#5C5C5D" />
                </button>
              </div>
            </div>
            <div className="flex flex-row flex-wrap px-4 md:px-8 mb-5 md:mb-10">
              <div className="w-full lg:px-5">
                <Card className="w-full p-6 md:p-8">
                  <PersonalInfos>
                    <div className="flex flex-wrap">
                      <div className="w-full px-2 mb-5">
                        <h2 className="font-bold text-lg">
                          Informações pessoais
                        </h2>
                      </div>
                      <div className="w-full md:w-6/12 lg:w-3/12 px-2 mb-5">
                        <p>
                          OS: <span>{order.id}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 lg:w-9/12 px-2 mb-5">
                        <p>
                          Status da OS: <span>{order.status}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 xl:w-3/12 px-2 mb-5">
                        <p>
                          Cliente: <span>{order.name}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 xl:w-2/12 px-2 mb-5">
                        <p>
                          Celular: <span>{order.cellphone}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 xl:w-4/12 px-2 mb-5">
                        <p>
                          Tipo: <span>{order.type}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 xl:w-3/12 px-2 mb-5">
                        <p>
                          Email: <span>{order.email}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-5/12 xl:w-3/12 px-2 mb-5">
                        <p>
                          Estado do Terreno: <span>{order.state}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-7/12 xl:w-3/12 px-2 mb-5">
                        <p>
                          Municipio do terreno: <span>{order.county}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-4/12 lg:w-4/12 xl:w-2/12 px-2 mb-5">
                        <p>
                          Agência: <span>{order.agency}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 lg:w-4/12 xl:w-2/12 px-2 mb-5">
                        <p>
                          Número do PIS <span>{order.pis_number}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-2/12 lg:w-4/12 xl:w-2/12 px-2 mb-5">
                        <p>
                          Casado <span>{order.married ? 'Sim' : 'Não'}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-full lg:w-6/12 px-2 mb-5">
                        <p>
                          Cônjuge: <span>{order.spouse}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 lg:w-3/12 px-2 mb-5">
                        <p>
                          CPF <span>{order.spouse_document}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 lg:w-3/12 px-2 mb-5">
                        <p>
                          Origem <span>{order.origin}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 px-2 mb-5">
                        <p>
                          Email <span>{order.spouse_email}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 px-2 mb-5">
                        <p>
                          Celular: <span>{order.spouse_phone}</span>
                        </p>
                      </div>
                    </div>
                    <hr className="my-4 mx-2" />
                    <div className="flex flex-wrap">
                      <div className="w-full px-2 mb-5">
                        <h2 className="font-bold text-lg">
                          Dados do construtor
                        </h2>
                      </div>
                      <div className="w-full md:w-6/12 lg:w-4/12 px-2 mb-5">
                        <p>
                          Engenheiro: <span>{order.builder}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 lg:w-3/12 px-2 mb-5">
                        <p>
                          Construtech: <span>{order.construtech}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 lg:w-4/12 px-2 mb-5">
                        <p>
                          E-mail: <span>{order.builder_email}</span>
                        </p>
                      </div>
                      <div className="w-full md:w-6/12 lg:w-full px-2 mb-5">
                        <p>
                          Telefone do responsavel técnico:{' '}
                          <span>{order.builder_telephone}</span>
                        </p>
                      </div>
                    </div>
                  </PersonalInfos>
                </Card>
              </div>
            </div>
            <Simulation className="flex flex-wrap px-4 md:px-8">
              <div className="w-full lg:w-5/12 lg:pl-5 lg:pr-3 mb-2 lg:mb-0">
                <Card className="p-6 md:p-8 h-full">
                  <div className="flex justify-between">
                    <h2 className="font-bold text-lg">
                      Composição do recursos
                    </h2>
                  </div>
                  <div className="flex md:items-center lg:items-start flex-col md:flex-row lg:flex-col">
                    <div className="w-full md:w-5/12 lg:w-full py-10">
                      <CompositionChart dataChart={dataChart} />
                    </div>
                    <div className="w-full md:w-6/12 lg:w-full">
                      <div className="flex justify-between my-6 lg:my-8">
                        <p className="before-circle circle-color-purple">
                          Valor financiado
                        </p>
                        <p className="color-android-green font-bold">
                          R$ {order.amount_financed}
                        </p>
                      </div>
                      <div className="flex justify-between my-6 lg:my-8">
                        <p className="before-circle circle-color-yellow">
                          Valor de entrada
                        </p>
                        <p className="color-android-green font-bold">
                          R$ {order.entry_value}
                        </p>
                      </div>
                      <div className="flex justify-between my-6 lg:my-8">
                        <p className="before-circle circle-color-orange">
                          Valor do FGTS
                        </p>
                        <p className="color-android-green font-bold">
                          R$ {order.fgts_value}
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="w-full lg:w-7/12 lg:pl-3 lg:pr-5 mt-2 lg:mt-0">
                <div className="flex flex-wrap mb-5">
                  <div className="w-full md:w-6/12 mb-2 md:mb-0">
                    <Card className="flex flex-col justify-between p-6 md:p-8 h-full">
                      <h2 className="font-bold text-lg mb-10">
                        Condições da simulação
                      </h2>
                      <div className="flex flex-col mb-10">
                        <div className="flex justify-between my-5">
                          <div className="flex items-center">
                            <p>Prazo do financiamento</p>
                            <HiOutlineInformationCircle
                              size={12}
                              color="#414142"
                              className="ml-1 md:ml-3"
                            />
                          </div>
                          <p className="color-android-green font-medium">
                            {order.deadline}{' '}
                            {order.deadline === 1 ? 'ano' : 'anos'}
                          </p>
                        </div>
                        <div className="flex justify-between my-5">
                          <div className="flex items-center">
                            <p>Taxa de juros</p>
                            <HiOutlineInformationCircle
                              size={12}
                              color="#414142"
                              className="ml-1 md:ml-3"
                            />
                          </div>
                          <p className="color-android-green font-medium">
                            {order.tax}% a.a
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="w-full md:w-6/12 md:pl-5 mt-2 md:mt-0">
                    <div className="flex flex-wrap">
                      <div className="w-full mb-2">
                        <Card className="p-6 md:p-8">
                          <div className="flex justify-between mb-10">
                            <h2 className="font-bold text-lg">
                              Valor do orçamento
                            </h2>
                          </div>
                          <div className="flex justify-between">
                            <p className="price-before price-orange-color">
                              R$ {order.budget}
                            </p>
                            <div className="w-6/12">
                              <PriceChart
                                label="Valor do orçamento"
                                value={65}
                                color="#FF9900"
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                      <div className="w-full mt-2">
                        <Card className="p-6 md:p-8">
                          <div className="flex justify-between mb-10">
                            <h2 className="font-bold text-lg">
                              Saldo do Terreno
                            </h2>
                          </div>
                          <div className="flex justify-between">
                            <p className="price-before price-yellow-color">
                              R$ {order.land_value}
                            </p>
                            <div className="w-6/12">
                              <PriceChart
                                label="Saldo do Terreno"
                                value={65}
                                color="#E8E371"
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <Card className="p-6 md:p-8">
                    <h3 className="color-onyx font-medium text-lg mb-3">
                      {order.amortization}
                    </h3>
                    <div className="flex flex-col md:flex-row md:justify-between mb-5">
                      <p>Valor total financiado</p>
                      <p className="color-onyx font-bold text-lg">
                        R$ {order.amount_financed}
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row md:justify-between my-5">
                      <div>
                        <p>Primeira Parcela</p>
                        <small>{order.initial_value_installment_date}</small>
                      </div>
                      <p>R$ {order.initial_value_installment}</p>
                    </div>
                    <div className="flex flex-col md:flex-row md:justify-between my-5">
                      <div>
                        <p>Última Parcela</p>
                        <small>{order.final_value_installment_date}</small>
                      </div>
                      <p>R$ {order.final_value_installment}</p>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="w-full lg:px-5 my-5 md:my-10">
                <Card className="p-6 md:p-8">
                  <div className="flex flex-row flex-wrap md:px-8">
                    <div className="w-full mb-3 md:mb-5">
                      <h2 className="font-bold text-lg">Dados da simulação</h2>
                    </div>
                    <div className="w-full lg:w-6/12">
                      <div className="hidden flex-wrap justify-between my-2">
                        <div className="w-5/12">
                          <p>Modalidade:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">***SBPE***</p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Valor do Imóvel:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            R$ {order.property_value}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Valor de entrada:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            R$ {order.entry_value}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Valor do FGTS:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            R$ {order.fgts_value}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Valor inicial da prestação:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            R$ {order.initial_value_installment}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Valor final da prestação:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            R$ {order.final_value_installment}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12">
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Banco:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">{order.bank}</p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Amortização:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            {order.amortization}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Valor do Financiado:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            R$ {order.amount_financed}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Valor do Subsídio:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            R$ {order.subsidy_amount}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Valor de entrada real:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            R$ {order.entry_value}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Valor do terreno:</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            R$ {order.land_value}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between my-2">
                        <div className="w-full md:w-5/12">
                          <p>Há preponentes?</p>
                        </div>
                        <div className="w-full md:w-7/12">
                          <p className="font-semibold value">
                            {order.proponents}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="w-full lg:px-5 my-5 md:my-10">
                <Card className="p-6 md:p-8">
                  <div className="w-full mb-3 md:mb-5">
                    <h2 className="font-bold text-lg">
                      {order?.type || 'N/A'}
                    </h2>
                  </div>
                  <div className="w-full flex flex-row flex-nowrap ">
                    <div className="flex-1 h-auto flex gap-4 flex-col">
                      {/* <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                        <span className="w-1/2 ">Tipo de terreno:</span>
                        <span className="font-semibold value  flex-1 flex flex-row justify-start">
                          {order?.complement_form?.terrain_or_build === 1 &&
                            'Rua'}
                          {order?.complement_form?.terrain_or_build === 0 &&
                            'Condomínio'}

                          {!order?.complement_form?.terrain_or_build && 'N/A'}
                        </span>
                      </span> */}
                      {order.typeID === 2 && (
                        <span
                          data-show={order.typeID === '2'}
                          className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4"
                        >
                          <span className="w-1/2 ">Possui RGI: </span>
                          <span className="font-semibold value  flex-1 flex flex-row justify-start">
                            {order?.complement_form?.has_rgi === 1 &&
                              'Sim, em meu nome'}
                            {order?.complement_form?.has_rgi === 2 &&
                              'Não / nome de terceiros'}
                            {order?.complement_form?.has_rgi === 3 &&
                              ' Nome de PJ'}
                            {!order?.complement_form?.has_rgi && 'N/A'}
                          </span>
                        </span>
                      )}
                      <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                        <span className="w-1/2 ">Valor do terreno: </span>
                        <span className="font-semibold value  flex-1 flex flex-row justify-start">
                          {Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(order.complement_form?.terrain_amount || 0)}
                        </span>
                      </span>
                      <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                        <span className="w-1/2 ">
                          Saldo devedor do terreno:
                        </span>
                        <span className="font-semibold value  flex-1 flex flex-row justify-start">
                          {Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(
                            order.complement_form?.land_outstanding_balance ||
                              0,
                          )}
                        </span>
                      </span>
                      <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                        <span className="w-1/2 ">
                          Valor total de obra (estimado):
                        </span>
                        <span className="font-semibold value  flex-1 flex flex-row justify-start">
                          {Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(
                            order.complement_form?.amount_build_estimated || 0,
                          )}
                        </span>
                      </span>
                      <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                        <span className="w-1/2 ">
                          Valor do imóvel pronto para compra e venda (estimado):
                        </span>
                        <span className="font-semibold value  flex-1 flex flex-row justify-start">
                          {Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(
                            order.complement_form?.property_value_estimated ||
                              0,
                          )}
                        </span>
                      </span>
                      {order.typeID === 2 && (
                        <span
                          data-show={order.typeID === '1'}
                          className="w-full flex flex-row flex-nowrap items-center justify-between gap-4"
                        >
                          <span className="w-1/2 ">
                            Quanto já gastou na obra:
                          </span>
                          <span className="font-semibold value  flex-1 flex flex-row justify-start">
                            {Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(
                              order.complement_form?.spent_of_project || 0,
                            )}
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="flex-1 h-auto flex gap-4 flex-col">
                      <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                        <span className="w-1/2 ">
                          Quanto ainda possui em recursos próprios
                          {order.typeID === 2
                            ? '(fora o que já foi gasto na obra)'
                            : ''}
                        </span>
                        <span className="font-semibold value  flex-1 flex flex-row justify-start">
                          {Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(
                            order.complement_form?.how_much_resources || 0,
                          )}
                        </span>
                      </span>
                      <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                        <span className="w-1/2 ">Renda Mensal Formal:</span>
                        <span className="font-semibold value  flex-1 flex flex-row justify-start">
                          {Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(
                            order.complement_form?.formal_monthly_income || 0,
                          )}
                        </span>
                      </span>
                      <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                        <span className="w-1/2 ">Renda Mensal Informal</span>
                        <span className="font-semibold value  flex-1 flex flex-row justify-start">
                          {Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(
                            order.complement_form?.unformal_monthly_income || 0,
                          )}
                        </span>
                      </span>
                      <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                        <span className="w-1/2 ">
                          Endereço completo do terreno com CEP:
                        </span>
                        <span className="font-semibold value  flex-1 flex flex-row justify-start">
                          {order.complement_form?.address_terrain || 'N/A'}
                        </span>
                      </span>

                      {order.typeID === 2 && (
                        <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                          <span className="w-1/2 ">
                            Terreno de rua ou condomínio:
                          </span>
                          <span className="font-semibold value  flex-1 flex flex-row justify-start">
                            {order.complement_form?.terrain_or_build || 'N/A'}
                          </span>
                        </span>
                      )}

                      {order.typeID === 1 && (
                        <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                          <span className="w-1/2 ">
                            Qual nome do Condomínio:
                          </span>
                          <span className="font-semibold value  flex-1 flex flex-row justify-start">
                            {order.complement_form?.name_of_build || 'N/A'}
                          </span>
                        </span>
                      )}

                      {order.typeID <= 2 && (
                        <span className=" w-full flex flex-row flex-nowrap items-center justify-between gap-4">
                          <span className="w-1/2 ">Cidade do Terreno:</span>
                          <span className="font-semibold value  flex-1 flex flex-row justify-start">
                            {order.complement_form?.city_of_terrain || 'N/A'}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </Simulation>
          </div>
        </Container>
      )}
      {/*
terrain_or_build
has_rgi
terrain_amount
land_outstanding_balance
amount_build_estimated
property_value_estimated
spent_of_project

how_much_resources
formal_monthly_income
unformal_monthly_income
city_of_terrain
build
address_terrain
name_of_build
                      */}
      <Modal show={showShowBankSlip} onHide={handleClose}>
        <Modal.Header className="mb-8">
          <div className="w-full">
            <h3 className="text-2xl color-onyx font-semibold mb-3 text-left">
              Enviar boleto de engenharia
            </h3>
            <p className="text-left">Selecione o(s) Documento(s) pedido</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-wrap justify-center px-0 md:px-8">
            <div className="w-full md:px-5 mb-4">
              <Dropzone
                data={bankSlips}
                onDrop={handleDropBankSlips}
                onRemove={handleRemoveBankSlip}
              />
              <button
                type="button"
                className="mt-6 md:mt-0 py-2 w-full rounded-full"
                onClick={handleSubmitBankSlips}
              >
                Salvar
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showAttachEngineeringReport} onHide={handleClose}>
        <Modal.Header className="mb-8">
          <div className="w-full">
            <h3 className="text-2xl color-onyx font-semibold mb-3 text-left">
              Anexar laudo de engenharia
            </h3>
            <p className="text-left">Selecione o(s) Documento(s) pedido</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRefReport} onSubmit={handleSubmitReports}>
            <div className="flex flex-wrap justify-center px-0 md:px-8">
              <div className="w-full md:px-5 mb-4">
                <Dropzone
                  data={reports}
                  onDrop={handleDropReports}
                  onRemove={handleRemoveReport}
                />
                <div>
                  <h4 className="text-xl color-onyx font-semibold mb-3 text-left">
                    Laudo do engenheiro
                  </h4>
                  <div className="flex flex-wrap w-full my-4 lg:pl-4">
                    <div className="w-6/12 text-left">
                      <div className="md:flex flex-wrap my-10">
                        <InputRadio
                          name="survey"
                          options={RadioOptionsSalesman}
                        />
                      </div>
                    </div>
                    <div className="w-6/12">
                      <InputNote />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="mt-6 md:mt-0 py-2 w-full rounded-full"
                >
                  Salvar
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showSubmitRegistrationForms} onHide={handleClose}>
        <Modal.Header className="mb-8">
          <div className="w-full">
            <h3 className="text-2xl color-onyx font-semibold mb-3 text-left">
              Enviar fichas cadastrais
            </h3>
            <p className="text-left">Selecione o(s) Documento(s) pedido</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRefRegistrationForms}
            onSubmit={handleSubmitRegistrationForms}
          >
            <div className="flex flex-wrap justify-center px-0 md:px-8">
              <div className="w-full md:px-5 mb-4">
                <Dropzone
                  data={registrationForms}
                  onDrop={handleDropRegistrationForms}
                  onRemove={handleRemoveRegistrationForm}
                />
                <div className="flex flex-wrap w-full my-4 lg:pl-4">
                  <InputNote />
                </div>
                <button
                  type="submit"
                  className="mt-6 md:mt-0 py-2 w-full rounded-full"
                >
                  Salvar
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showReleaseSignatureOnline} onHide={handleClose}>
        <Modal.Header className="mb-8">
          <div className="w-full">
            <h3 className="text-2xl color-onyx font-semibold mb-3 text-left">
              Liberar assinaturas
            </h3>
            <p className="text-left">Selecione o(s) Documento(s) pedido</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRefReleaseSignatureOnline}
            onSubmit={handleSubmitReleaseSignatureOnline}
          >
            <div className="flex flex-wrap justify-center px-0 md:px-8">
              <div className="w-full md:px-5 mb-4">
                <Dropzone
                  data={signatureDocuments}
                  onDrop={handleDropReleaseSignatureOnline}
                  onRemove={handleRemoveReleaseSignatureOnline}
                />
                <div className="flex flex-wrap w-full my-4 lg:pl-4">
                  <div className="w-6/12 pr-2">
                    <InputNote />
                  </div>
                  <div className="w-6/12 pl-2">
                    <label className="text-left block mb-2">Link</label>
                    <div className="box py-2">
                      <Input type="link" name="link" className="border-none" />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="mt-6 md:mt-0 py-2 w-full rounded-full"
                >
                  Salvar
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showReleaseSignatureCaixa} onHide={handleClose}>
        <Modal.Header className="mb-8">
          <div className="w-full">
            <h3 className="text-2xl color-onyx font-semibold mb-3 text-left">
              Liberar assinaturas
            </h3>
            <p className="text-left">Selecione o(s) Documento(s) pedido</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRefReleaseSignatureCaixa}
            onSubmit={handleSubmitReleaseSignatureCaixa}
          >
            <div className="flex flex-wrap justify-center px-0 md:px-8">
              <div className="w-full md:w-8/12 md:px-5 mb-4">
                <div className="flex flex-wrap w-full my-4 lg:pl-4">
                  <div className="w-full">
                    <InputNote />
                  </div>
                </div>
                <button
                  type="submit"
                  className="mt-6 md:mt-0 py-2 w-full rounded-full"
                >
                  Salvar
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ChatOptions>
        <button type="button" className="btn-chat" onClick={handleClickBtnChat}>
          <BsFillChatDotsFill size={20} color="#fff" />
        </button>
      </ChatOptions>
      <Loading active={loading} />
    </div>
  );
}
