/* eslint-disable react/prop-types */
import { createContext, useContext, useMemo, useState } from 'react';

export const DatatableContext = createContext({});

export const DatatableProvider = ({ children }) => {
  const [per_page, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState('');
  const [column, setColumn] = useState('');
  const [order, setOrder] = useState('asc');

  const params = useMemo(() => {
    return {
      per_page,
      totalRecords,
      page,
      pageCount,
      search,
      column,
      order,
      setPerPage,
      setTotalRecords,
      setPage,
      setPageCount,
      setSearch,
      setColumn,
      setOrder,
    };
  }, [per_page, totalRecords, page, pageCount, search, column, order]);

  return (
    <DatatableContext.Provider value={params}>
      {children}
    </DatatableContext.Provider>
  );
};

export function useDatatable() {
  const context = useContext(DatatableContext);

  if (!context) {
    throw new Error('useDatatable must be used within an DatatableProvider');
  }

  return context;
}
