import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';

import { Container, Content, Menu, MenuMobileButton } from './styles';

import logo from '~/assets/logos/logo.png';

export default function Header() {
  const { signOut } = useAuth();
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, []);

  return (
    <Container className="flex flex-col justify-around items-center text-center menu-header">
      <Content>
        <div className="flex lg:flex-col justify-between items-center lg:pb-3 px-0 sm:px-5 lg:px-0">
          <Link to={`${process.env.PUBLIC_URL}/home`}>
            <img src={logo} alt="logo" className="w-full lg:w-75" />
          </Link>

          <MenuMobileButton
            type="button"
            className="bg-transparent block lg:hidden border-none mr-3"
            onClick={() => setActive(!active)}
            active={active}
          >
            <span />
            <span />
            <span />
          </MenuMobileButton>
        </div>
        <Menu active={active} className="w-full">
          <div className="flex flex-col relative menu-group active">
            <div className="flex flex-col justify-center py-3 lg:py-1 menu">
              <Link
                to={`${process.env.PUBLIC_URL}/home`}
                className={`menu-item py-5 w-100 w-lg-auto flex flex-col justify-center items-center ${
                  location.pathname.match(/\/home.*/) ? 'active' : ''
                }`}
              >
                <div className="dashboard" />
                <small className="mt-2 block text-center">Menu</small>
              </Link>
            </div>
            <div className="flex flex-col justify-center py-3 lg:py-1 menu">
              <Link
                to={`${process.env.PUBLIC_URL}/mensagens`}
                className={`menu-item py-5 w-100 w-lg-auto flex flex-col justify-center items-center ${
                  location.pathname.match(/\/mensagens.*/) ? 'active' : ''
                }`}
              >
                <div className="chat" />
                <small className="mt-2 block text-center">Mensagens</small>
              </Link>
            </div>
            <div className="flex flex-col justify-end py-3 lg:py-1 lg:h-full logoff">
              <button
                type="button"
                onClick={() => signOut()}
                className="border-0 menu-item py-2 py-lg-2 py-xxl-3 w-full flex flex-col justify-center items-center"
              >
                <div className="logout" />
                <small className="mt-2 block text-center">Sair</small>
              </button>
            </div>
          </div>
        </Menu>
      </Content>
    </Container>
  );
}
