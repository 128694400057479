import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useValidError } from '~/hooks/ValidError';
import api from '~/services/api';

import {
  Button,
  Container,
  Content,
  Link,
  Menu,
  MenuMobileButton,
} from './styles';

import logo from '~/assets/logos/logo.png';

export default function HeaderDashboard({
  slug,
  onShowBankSlip,
  onShowAttachEngineeringReport,
  onShowSubmitRegistrationForms,
  onShowReleaseSignature,
  onClickDownloadProposal,
}) {
  const { validError } = useValidError();
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    api
      .get(`correspondents/oss/${slug}`)
      .then(response => {
        setStep(response.data.status.step.ordem);
      })
      .catch(error => {
        validError(error);
      });
  }, [slug, validError]);

  useEffect(() => {
    if (active) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [active]);

  return (
    <>
      <Container className="flex flex-col justify-start items-center text-center menu-header">
        <Content>
          <div className="flex lg:flex-col justify-between items-center lg:pb-1 xl:pb-3 px-0 sm:px-5 lg:px-0">
            <Link to={`${process.env.PUBLIC_URL}/home`} released>
              <img src={logo} alt="logo" className="w-75" />
            </Link>

            <MenuMobileButton
              type="button"
              className="bg-transparent block lg:hidden border-none mr-3"
              onClick={() => setActive(!active)}
              active={active}
            >
              <span />
              <span />
              <span />
            </MenuMobileButton>
          </div>
          <Menu active={active} className="w-full">
            <div className="flex flex-col items-center relative menu-group">
              <div className="flex flex-col justify-center lg:w-full items-center py-3 lg:py-1 lg:h-full bg-white menu">
                <Link
                  to={`${process.env.PUBLIC_URL}/ordem-de-servico/${slug}`}
                  className={`menu-item px-2 py-3 w-full flex lg:justify-center items-center ${
                    location.pathname.match(/\/ordem-de-servico.*/)
                      ? 'active'
                      : ''
                  }`}
                  released
                >
                  <div className="order-of-service" />
                  <small>Ordem de serviço</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/documentos/verificar-documentos/${slug}`}
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  released={step >= 5}
                >
                  <div className="check-documents" />
                  <small>Verificar documentos</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/documentos/resultado-analise-de-credito/${slug}`}
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  released={step >= 5}
                >
                  <div className="credit-analysis-result" />
                  <small>Resultado análise de crédito</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/documentos/analise-de-abertura-de-processo/${slug}`}
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  released={step >= 7}
                >
                  <div className="process-opening-analysis" />
                  <small>Análise de abertura de processo</small>
                </Link>
                <Button
                  type="button"
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  onClick={onShowBankSlip}
                  released={step >= 8}
                >
                  <div className="upload-engineering-ticket" />
                  <small>Subir boleto de engenharia</small>
                </Button>
                <Button
                  type="button"
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  onClick={onShowAttachEngineeringReport}
                  released={step >= 9}
                >
                  <div className="attach-engineering-report" />
                  <small>Anexar laudo de engenharia</small>
                </Button>
                <Link
                  to={`${process.env.PUBLIC_URL}/documentos/resultado-da-conformidade/${slug}`}
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  released={step >= 9}
                >
                  <div className="compliance-result" />
                  <small>Resultado da conformidade</small>
                </Link>
                <Button
                  type="button"
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  onClick={onShowSubmitRegistrationForms}
                  released={step >= 10}
                >
                  <div className="registration-forms" />
                  <small>Fichas cadastrais</small>
                </Button>
                <Button
                  type="button"
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  onClick={onShowReleaseSignature}
                  released={step >= 11}
                >
                  <div className="release-contract-signature" />
                  <small>Liberar assinatura de contrato</small>
                </Button>
                <button
                  type="button"
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  onClick={onClickDownloadProposal}
                >
                  <div className="download-proposal" />
                  <small>Baixar proposta</small>
                </button>
                <Link
                  to={`${process.env.PUBLIC_URL}/documentos/gerenciador-de-arquivos/${slug}`}
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  released
                >
                  <div className="file-manager" />
                  <small>Gerenciador de arquivos</small>
                </Link>
              </div>
              <div className="d-flex flex-col py-3 lg:w-full logoff">
                <Link
                  to={`${process.env.PUBLIC_URL}/home`}
                  className="menu-item px-2 py-3 w-full flex lg:justify-center items-center"
                  released
                >
                  <div className="logout" />
                  <small className="mt-2 block text-center">Menu</small>
                </Link>
              </div>
            </div>
          </Menu>
        </Content>
      </Container>
      {/* <Cont>
        <div className="wrapper">
          <ul>
            <li>Abc</li>
            <li>Def</li>
            <li>Ghi</li>
            <li>Jkl</li>
            <a className="parent">
              <div>
                <span>Mno</span>
                <small className="wrapper">PAGINA</small>
              </div>
            </a>
            <li>Pqr</li>
            <li>Stu</li>
            <li>Vw</li>
            <li>Xyz</li>
            <li className="parent">
              Abc
              <div className="wrapper">
                <ul>
                  <li>Abc</li>
                  <li>Def</li>
                  <li>Ghi</li>
                  <li>Jkl</li>
                  <li>Mno</li>
                  <li>Pqr</li>
                  <li>Stu</li>
                  <li>Vw</li>
                  <li>Xyz</li>
                </ul>
              </div>
            </li>
            <li>Def</li>
            <li>Ghi</li>
            <li>Jkl</li>
            <li>Mno</li>
            <li>Pqr</li>
            <li>Stu</li>
            <li>Vw</li>
            <li>Xyz</li>
          </ul>
        </div>
      </Cont> */}
    </>
  );
}

HeaderDashboard.propTypes = {
  slug: PropTypes.string.isRequired,
  onShowBankSlip: PropTypes.func,
  onShowAttachEngineeringReport: PropTypes.func,
  onShowSubmitRegistrationForms: PropTypes.func,
  onShowReleaseSignature: PropTypes.func,
  onClickDownloadProposal: PropTypes.func,
};

HeaderDashboard.defaultProps = {
  onShowBankSlip: () => {},
  onShowAttachEngineeringReport: () => {},
  onShowSubmitRegistrationForms: () => {},
  onShowReleaseSignature: () => {},
  onClickDownloadProposal: () => {},
};
